<template>
  <v-progress-circular v-if="loading" indeterminate size="24" width="3" class="mx-2" color="grey" />
  <div v-else class="d-flex justify-end">
    <template v-if="simple">
      <v-icon
        v-for="action in buttonActions"
        :key="action.label"
        :small="smallButtons"
        class="ml-2"
        @click.stop="onActionClick(action, $event)"
        >{{ action.icon }}</v-icon
      >
    </template>
    <template v-else>
      <v-tooltip
        v-for="action in buttonActions"
        :key="action.label"
        :content-class="inDialog ? 'tooltip-in-dialog' : ''"
        open-delay="500"
        bottom
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              :disabled="disabled || action.disabled"
              :loading="action.loading"
              :small="smallButtons"
              icon
              @click.stop="onActionClick(action, $event)"
            >
              <v-icon :small="smallButtons">{{ action.icon }}</v-icon>
            </v-btn>
          </div>
        </template>
        <span>
          {{ action.label }}
        </span>
      </v-tooltip>
    </template>
    <v-menu v-if="menuActions.length" :left="left" offset-y>
      <template v-slot:activator="{ on: onMenu }">
        <template v-if="simple">
          <v-icon
            v-on="onMenu"
            class="js-actions-menu-activator ml-2"
            :small="smallButtons"
            @click.stop
            >mdi-dots-vertical</v-icon
          >
        </template>
        <template v-else>
          <v-tooltip v-on="onMenu" open-delay="500" bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                v-on="{ ...onMenu, ...onTooltip }"
                :small="smallButtons"
                class="js-actions-menu-activator"
                icon
                @click.stop
              >
                <v-icon :small="smallButtons">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('more_actions') }}
            </span>
          </v-tooltip>
        </template>
      </template>
      <slot>
        <v-list>
          <v-list-item
            v-for="action in menuActions"
            :key="action.label"
            :disabled="disabled || action.disabled || action.loading"
            :loading="action.loading"
            @click="onActionClick(action, $event)"
          >
            <v-list-item-icon>
              <v-icon v-if="action.icon">
                {{ action.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ action.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'BaseActionMenu',

  props: {
    actions: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    item: {
      type: [Object, null],
      default: null,
    },

    maxButtons: {
      type: Number,
      default: 2,
    },

    simple: {
      // removes tooltips and replaces v-btns with v-icons which reduces render times in large tables
      type: Boolean,
      default: false,
    },

    smallButtons: {
      type: Boolean,
      default: false,
    },

    left: {
      type: Boolean,
      default: false,
    },

    inDialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonActions() {
      if (!this.actions.length) {
        return [];
      }
      return this.actions.length <= this.maxButtons
        ? this.actions
        : this.actions.slice(0, this.maxButtons - 1);
    },

    menuActions() {
      if (this.actions.length <= this.maxButtons) {
        return [];
      }
      return this.actions.slice(this.maxButtons - 1);
    },
  },

  methods: {
    onActionClick(action, domEvent) {
      const payload = this.item ? this.item : domEvent;
      action.callback(payload);
    },
  },
};
</script>
