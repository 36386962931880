<template>
  <v-scale-transition>
    <v-btn
      v-if="$vuetify.breakpoint[breakpoint]"
      :text="text"
      color="primary"
      @click.stop="$emit('click')"
    >
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>
    <v-btn v-else color="primary" bottom dark fab fixed right @click.stop="$emit('click')">
      <v-icon>
        {{ icon }}
      </v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'BasePrimaryActionButton',

  props: {
    icon: {
      type: String,
      default: 'mdi-plus',
    },

    label: {
      type: String,
      default: '',
    },

    breakpoint: {
      type: String,
      default: 'lgAndUp',
    },

    text: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
