/* eslint no-param-reassign: ["error", { "props": false }] */

// Some general table code that dries up table components.
// Usage example can be found in UserTable.vue

const tableMixin = {
  data() {
    return {
      tableRowKey: 'id', // override if it's different
      tableMixin_expandedRows: [],
    };
  },

  computed: {
    tableMixin_displayedHeaders() {
      return this.headers.filter(h => !this.$vuetify.breakpoint[h.hidden]);
    },

    tableMixin_hiddenHeaders() {
      return this.headers.filter(h => this.$vuetify.breakpoint[h.hidden] && !h.hideInExpandedRow);
    },

    tableMixin_firstExpandedRowKey() {
      return this.tableMixin_expandedRows.length
        ? this.tableMixin_expandedRows[0][this.tableRowKey]
        : null;
    },
  },

  methods: {
    tableMixin_onRowClick(item) {
      if (this.tableMixin_hiddenHeaders.length) {
        this.tableMixin_expandRow(item);
      } else {
        this.$emit('row-click', item);
      }
    },

    tableMixin_expandRow(item) {
      if (this.tableMixin_firstExpandedRowKey !== item[this.tableRowKey]) {
        this.tableMixin_expandedRows = [item];
        this.$emit('row-expand', item);
      } else {
        this.tableMixin_expandedRows = [];
      }
    },

    tableMixin_changePage(page) {
      if (page !== this.pagination.page) {
        this.$emit('change-page', page);
      }
    },
  },
};

export default tableMixin;
